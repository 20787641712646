import {
	keysPublicApi
} from '@dg/common/lib/api'
import titleData from '@dg/common/public/json/title'

/* DEVICE */
const device = (userAgent?: string, chkOnly = true) => {
	const chkUa = {} as Record<string, boolean | string | undefined>

	let dgUa = {} as {
		app?: string;
		appVersion?: string;
		browser?: string;
		osVersion?: string;
		platform?: string;
		version?: string;
	}

	const uaMatch = (ua: string) => {
		const uaLow = ua.toLowerCase()

		const chkQoo10App: undefined[] | RegExpExecArray =
			(/(gmarket qoo10 jp)([\w.]+)/).exec(uaLow) ??
			[]

		const chkMoveApp: undefined[] | RegExpExecArray =
			(/(iphone_move|android_move)([\w.]+)/).exec(uaLow) ??
			[]

		const chk1 = uaLow.indexOf(`trident`) >= 0 && (/(rv)(?::| )([\w.]+)/).exec(uaLow)
		const chk1Value = chk1 !== false ? chk1 : undefined
		const chk2 = uaLow.indexOf(`compatible`) < 0 && (/(mozilla)(?:.*? rv:([\w.]+)|)/).exec(uaLow)
		const chk2Value = chk2 !== false ? chk2 : undefined

		const chkBr: undefined[] | RegExpExecArray =
			(/(edg)[ \\/]([\w.]+)/).exec(uaLow) ??
			(/(opr)[\\/]([\w.]+)/).exec(uaLow) ??
			(/(whale)[ \\/]([\w.]+)/).exec(uaLow) ??
			(/(chrome)[ \\/]([\w.]+)/).exec(uaLow) ??
			(/(crios)[ \\/]([\w.]+)/).exec(uaLow) ??
			(/(iemobile)[\\/]([\w.]+)/).exec(uaLow) ??
			(/(safari)[ \\/]([\w.]+)/).exec(uaLow) ??
			(/(webkit)[ \\/]([\w.]+)/).exec(uaLow) ??
			(/(opera)(?:.*version|)[ \\/]([\w.]+)/).exec(uaLow) ??
			(/(msie) ([\w.]+)/).exec(uaLow) ??
			chk1Value ??
			chk2Value ??
			[]

		const chkOs: undefined[] | RegExpExecArray =
			(/(ipad)/).exec(uaLow) ??
			(/(ipod)/).exec(uaLow) ??
			(/(windows phone)/).exec(uaLow) ??
			(/(iphone)/).exec(uaLow) ??
			(/(android)/).exec(uaLow) ??
			(/(blackberry)/).exec(uaLow) ??
			(/(win)/).exec(uaLow) ??
			(/(mac)/).exec(uaLow) ??
			(/(linux)/).exec(uaLow) ??
			[]

		const chkApp = chkQoo10App.length > 0 ? chkQoo10App : chkMoveApp

		let osVersion

		if (chkOs[0] === `iphone` || chkOs[0] === `ipad` || chkOs[0] === `android`) {
			const chkOsVer: undefined[] | RegExpExecArray =
				(/(os)[ \\/]([\w.]+)/).exec(uaLow) ??
				(/(android)[ \\/]([\w.]+)/).exec(uaLow) ??
				[]

			osVersion = chkOsVer[2]?.split(`_`).join(`.`)
		}

		return {
			app: chkApp[1]?.replace(/gmarket qoo10 jp/gim, `qoo10`).replace(/(android|iphone)_/gim, ``),
			appVersion: chkApp[2]?.split(`_`)[1],
			browser: chkBr[1],
			osVersion,
			platform: chkOs[0],
			version: chkBr[2]
		}
	}

	dgUa = uaMatch((typeof window !== `undefined` ? window.navigator.userAgent : userAgent) ?? ``)

	if (dgUa.app !== undefined) {
		chkUa.app = dgUa.app
		chkUa.appVersion = dgUa.appVersion
	}

	if (dgUa.osVersion !== undefined) {
		chkUa.osVersion = dgUa.osVersion
	}

	if (dgUa.browser !== undefined) {
		chkUa[dgUa.browser] = true
		chkUa.version = dgUa.version
	}

	if (dgUa.platform !== undefined) {
		chkUa[dgUa.platform] = true
	}

	if (
		chkUa.android !== undefined ||
		chkUa.blackberry !== undefined ||
		chkUa.ipad !== undefined ||
		chkUa.iphone !== undefined ||
		chkUa.ipod !== undefined ||
		chkUa[`windows phone`] !== undefined
	) {
		chkUa.mobile = true
	}

	if (chkUa.mac !== undefined || chkUa.linux !== undefined || chkUa.win !== undefined) {
		chkUa.desktop = true
	}

	if (
		chkUa.chrome !== undefined ||
		chkUa.opr !== undefined ||
		chkUa.safari !== undefined ||
		chkUa.whale !== undefined
	) {
		chkUa.webkit = true
	}

	if (chkUa.rv !== undefined || chkUa.iemobile !== undefined) {
		const ie = `msie`

		dgUa.browser = ie
		chkUa[ie] = true
	}

	if (chkUa.safari !== undefined && chkUa.blackberry !== undefined) {
		const blackberry = `blackberry`

		dgUa.browser = blackberry
		chkUa[blackberry] = true
	}

	if (chkUa.safari !== undefined && chkUa.android !== undefined) {
		const android = `android`

		dgUa.browser = android
		chkUa[android] = true
	}

	if (typeof window !== `undefined` && chkOnly === false) {
		const appVersionValue = chkUa.appVersion as string
		const versionValue = chkUa.version as string
		const versionNum = (versionValue || appVersionValue).split(`.`)

		// 기종 체크
		const body = document.querySelector(`body`) as HTMLElement

		body.setAttribute(`class`, ``)

		if (chkUa.mobile !== undefined) {
			body.classList.add(`mobile`)
		} else {
			body.classList.add(`pc`)
		}

		// OS 체크
		if (chkUa.win !== undefined) {
			body.classList.add(`win`)
		} else if (chkUa.mac !== undefined) {
			body.classList.add(`mac`)
		} else if (chkUa.iphone !== undefined) {
			body.classList.add(`ios`)
		} else if (chkUa.ipad !== undefined) {
			body.classList.add(`ipad`)
		} else if (chkUa.android !== undefined) {
			body.classList.add(`android`)
		} else {
			body.classList.add(`other`)
		}

		// App 체크
		if (chkUa.app !== undefined) {
			body.classList.add(
				`app`,
				`app${appVersionValue}`,
				chkUa?.app as string
			)
		}

		// 브라우저 종류, 버전 체크
		if (chkUa.msie !== undefined) {
			body.classList.add(`ie${versionNum[0]}`)
		} else if (chkUa.chrome !== undefined || chkUa.crios !== undefined) {
			body.classList.add(`cr${versionNum[0]}`)
		} else if (chkUa.safari !== undefined) {
			body.classList.add(`sf${versionNum[0]}`)
		} else if (chkUa.edg !== undefined) {
			body.classList.add(`edg${versionNum[0]}`)
		} else if (chkUa.mozilla !== undefined) {
			body.classList.add(`ff${versionNum[0]}`)
		} else if (chkUa.whale !== undefined) {
			body.classList.add(`wha${versionNum[0]}`)
		} else {
			body.classList.add(`other${versionNum[0]}`)
		}
	}

	return {
		browser: chkUa
	}
}

/* ADD COMMA */
const addComma = (value: number) => {
	const data = {
		number: value.toString(),
		point: 0,
		str: ``,
		symbol: ``
	}

	let {
		number, symbol, point, str
	} = data

	if (number.startsWith(`-`) || number.startsWith(`+`)) {
		symbol = number.substring(0, 1)
		number = number.substring(1, number.length)
	}

	point = number.length % 3
	str = number.substring(0, point)

	while (point < number.length) {
		if (str !== ``) {
			str += `,`
		}

		str += number.substring(point, point + 3)
		point += 3
	}

	return `${symbol}${str}`
}

/* ADD COOKIE
	term: -1일 때 session
*/
const addCookie = (name: string, term = 1) => {
	const expireTime = new Date()

	const termDays = 1000 * 60 * 60 * 24 * term
	const defaultDay = new Date(new Date().getTime() + termDays)

	const defaultTime =
		termDays % (1000 * 60 * 60 * 24) === 0 ?
			`00:00:00` :
			`${defaultDay.getHours().toString().padStart(2, `0`)}:${defaultDay.getMinutes().toString().padStart(2, `0`)}:${defaultDay.getSeconds().toString().padStart(2, `0`)}`

	const expireTermsDay =
		`
			${defaultDay.getFullYear().toString()}-
			${(defaultDay.getMonth() + 1).toString().padStart(2, `0`)}-
			${defaultDay.getDate().toString().padStart(2, `0`)}
		`.trim().replace(/\n\s+/gim, ``)

	if (Number.isNaN(expireTime.setTime(new Date(`${expireTermsDay} ${defaultTime}`).getTime())) === false) {
		Number.isNaN(expireTime.setTime(new Date(`${expireTermsDay} ${defaultTime}`).getTime()))
	} else { // iOS 15이하
		expireTime.setTime(new Date(`${expireTermsDay}T${defaultTime}`).getTime())
	}

	document.cookie = `${name};domain=.qoo10.jp;path=/;${term > -1 ? `expires=${expireTime.toUTCString()};` : ``}`

	/*
	const dgCookieName = `${name}=`
	if(document.cookie.match(dgCookieName) !== null) {
		console.log(document.cookie.split(dgCookieName)[1].split(';')[0])
	}
	*/
}

/* ADD HYPHEN */
const addHyphen = (value: number | string, splitLength: number[] | number) => {
	const data = {
		arrayCnt: 0,
		number: value.toString().replace(/-/gim, ``),
		point: typeof splitLength !== `number` ? splitLength[0] : splitLength,
		str: ``
	}

	const {
		number
	} = data

	let {
		arrayCnt, point, str
	} = data

	for (const [
		index
	] of Array.from(Object.keys(number)).entries()) {
		if (typeof splitLength !== `number`) {
			if (index === point) {
				arrayCnt += 1

				point += splitLength[arrayCnt]

				str += `-`
			}

			str += number[index]
		} else {
			if (index % point === 0 && index > 0) {
				str += `-`
			}

			str += number[index]
		}
	}

	return str
}

/* API FUNC (with Axios) */
const apiFunc = async (
	url: string,
	type = `get` as `get` | `post` | `put` | `delete`,
	data: unknown[] | URLSearchParams | Record<string, unknown> | undefined = undefined
) => {
	const apiUrl = process.env.apiUrl as string
	const newUrl = url.match(apiUrl) !== null ? new URL(url) : url

	const convData = data as Record<string, unknown>

	let cookieData = ``

	if (convData?.cookies) {
		const findAccessToken = (convData.cookies as {
			name: string;
			value: string;
		}[]).find((item) => item.name === `access-token`)

		const findRefreshToken = (convData.cookies as {
			name: string;
			value: string;
		}[]).find((item) => item.name === `refresh-token`)

		cookieData =
			(convData.cookies as {
				name: string;
				value: string;
			}[])
				.filter((filterItem) => filterItem.name.match(/-token/))
				.map((item) => `${item.name}=${item.value}`).join(`;`)

		if (findAccessToken === undefined) {
			cookieData += `;access-token=${findRefreshToken?.value}`
		} else if (findRefreshToken === undefined) {
			cookieData += `;refresh-token=${findAccessToken?.value}`
		}
	}

	if (type === `get` && data !== undefined && typeof newUrl !== `string`) {
		for (const key of Object.keys(data as Record<string, string>)) {
			const dataArray = data as Record<string, string>
			const paramsData = key === `cookies` ? cookieData : dataArray[key]

			newUrl.searchParams.append(key, paramsData)
		}
	}

	const clientUserAgent = typeof window !== `undefined` ? window.navigator.userAgent : ``

	const fetchData = await fetch(newUrl, {
		body: type !== `get` ? JSON.stringify(convData) : undefined,
		cache: `no-store`,
		headers: {
			Accept: `application/json, text/plain`,
			'Content-Type': `application/json;charset=UTF-8`,
			Cookie: cookieData,
			'User-Agent': convData?.userAgent !== undefined ? convData.userAgent as string : clientUserAgent
		},
		method: type
	})

	const chkDataFunc = async (chkData: Response) => {
		const contentType = chkData.headers.get(`content-type`)

		let resultData

		if (contentType?.includes(`application/json`)) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			resultData = await chkData.json()
		} else {
			resultData = await chkData.text()
		}

		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return resultData
	}

	try {
		if (fetchData.ok === false) {
			// eslint-disable-next-line @typescript-eslint/only-throw-error
			throw chkDataFunc(fetchData)
		}

		const resultdata = await chkDataFunc(fetchData) as unknown

		return {
			data: resultdata
		}
	} catch (error) {
		const appConfigData = JSON.parse(process.env.appConfig ?? `{"npm_package_name":""}`) as {
			appEnv: `pretest` | `test` | `production`;
			npm_package_name: `live` | `member`;
		}

		// STG
		const phaseTest = appConfigData.appEnv === `test`

		const errorMessage = error instanceof Error ? error.message : `Unknown Error`

		class ValidationError extends Error {
			errorMessage: string

			response: {
				data: unknown;
				status: number;
				statusText: string;
			}

			constructor (responseData: {
				errorMessage: string;
				response: {
					data: unknown;
					status: number;
					statusText: string;
				};
			}) {
				super()

				this.name = `apiError`
				this.errorMessage = responseData.errorMessage
				this.response = responseData.response

				if (typeof Error !== `undefined`) {
					if (process.env.NODE_ENV === `development`) {
						console.log(
							this.name,
							this.stack,
							this.errorMessage,
							this.response?.data,
							this.response?.status,
							this.response?.statusText
						)
					} else if (phaseTest === true) {
						let serverUrl = process.env.memberUrl

						if (appConfigData.npm_package_name === `live`) {
							serverUrl = process.env.liveUrl
						}

						fetch(`${serverUrl}/api-message?msg=${JSON.stringify({
							message: `${this as unknown as string} - ${this.errorMessage ?? ``}`,
							name: `[Common] ${this.name} (${newUrl})`,
							stack:
							`
								Error: ${JSON.stringify(this.response ?? ``)}
								Query: ${(data as URLSearchParams).toString()}
								Stack: ${JSON.stringify(this.stack)}
							`
						})}`)
					}
				}
			}
		}

		throw new ValidationError({
			errorMessage,
			response: {
				data: await error,
				status: fetchData.status,
				statusText: fetchData.statusText
			}
		})
	}
}

interface appVersionChkFuncProps {
	android?: {
		move?: string;
		qoo10?: string;
	};
	ios?: {
		move?: string;
		qoo10?: string;
	};
}

const appVersionChkFunc = ({
	ios,
	android
}: appVersionChkFuncProps) => {
	const appVersionChk = (device().browser.appVersion ?? `1.0.0`) as string
	const appversionSplitArray = Array.from(appVersionChk.split(`.`))

	const numberItem1 = Number(appversionSplitArray[0])
	const numberItem2 = Number(appversionSplitArray[1])
	const numberItem3 = Number(appversionSplitArray[2])

	const chkFunc = (chkVersion: string) => {
		const chkAppversionSplitArray = Array.from(chkVersion.split(`.`))

		const chkNumberItem1 = Number(chkAppversionSplitArray[0])
		const chkNumberItem2 = Number(chkAppversionSplitArray[1])
		const chkNumberItem3 = Number(chkAppversionSplitArray[2])

		const chk1 = numberItem1 < chkNumberItem1
		const chk1verse = numberItem1 === chkNumberItem1
		const chk2 = numberItem2 < chkNumberItem2
		const chk2verse = numberItem2 === chkNumberItem2
		const chk3 = numberItem3 < chkNumberItem3

		if (chk1 === true) {
			return false
		}

		if (chk1verse === true && chk2 === true) {
			return false
		}

		if (chk1verse === true && chk2verse === true && chk3 === true) {
			return false
		}

		return true
	}

	if (device().browser.app === `qoo10`) {
		if (device().browser.android === undefined) {
			return chkFunc(ios?.qoo10 ?? `1.0.0`)
		}

		return chkFunc(android?.qoo10 ?? `1.0.0`)
	}

	if (device().browser.android === undefined) {
		return chkFunc(ios?.move ?? `1.0.0`)
	}

	return chkFunc(android?.move ?? `1.0.0`)
}

/* CHECK LENGTH */
const chkText = (obj: HTMLInputElement | HTMLTextAreaElement, maxByte = 100, type = `byte`, trim = false) => {
	const data = {
		currentLength: 0,
		length: Array.from(obj.value).length,
		result: {
			end: false,
			length: 0
		},
		totalByte: 0,
		value: obj.value
	}

	const {
		length, result, value
	} = data

	let {
		currentLength, totalByte
	} = data

	for (let i = 0; i < length; i += 1) {
		const oneChar = Array.from(value)[i]

		let chkContinue = true

		if (trim === true && oneChar.match(/\s/gim) !== null) {
			chkContinue = false
		}

		if (chkContinue === true) {
			if (type === `byte`) {
				totalByte += new Blob([
					oneChar
				]).size
			} else if (oneChar === `\uFE0F` || oneChar.match(/\uD83C[\uDFFB-\uDFFF]/) !== null) {
				totalByte += 0
			} else if (oneChar.match(/\u200D/) !== null) {
				if (device().browser.msie !== true) {
					totalByte -= 1
				} else {
					totalByte += 0
				}
			} else if (oneChar.match(/\uD83C[\uDDE6-\uDDFF]/) !== null) {
				if (device().browser.msie !== true) {
					totalByte += 0.5
				} else {
					totalByte += 1
				}
			} else {
				totalByte += 1
			}
		}

		if (totalByte <= maxByte) {
			currentLength = i + 1
		}

		if (totalByte >= maxByte) {
			const setObj = obj

			setObj.value = Array.from(value).slice(0, currentLength).join(``)

			result.end = true
			result.length = maxByte
		} else {
			result.end = false
			result.length = totalByte
		}
	}

	return result
}

/* WINDOW SCROLL */
const dgScroll = (scrollBeforefunc: () => void, scrollAfterfunc: () => void) => {
	let debounceDelayfunc = null as unknown as ReturnType<typeof setTimeout>

	const debounce = (beforeFunc: () => void, afterFunc: () => void, delay: number) => () => {
		if (typeof beforeFunc === `function`) {
			beforeFunc()
		}

		clearTimeout(debounceDelayfunc)
		debounceDelayfunc = setTimeout(() => {
			if (typeof afterFunc === `function`) {
				afterFunc()
			}
		}, delay)
	}

	window.addEventListener(`scroll`, debounce(scrollBeforefunc, scrollAfterfunc, 200))
}

/* WINDOW RESIZE */
const dgResize = (resizeBeforefunc: () => void, resizeAfterfunc: () => void) => {
	let debounceDelayfunc = null as unknown as ReturnType<typeof setTimeout>

	const debounce = (beforeFunc: () => void, afterFunc: () => void, delay: number) => () => {
		if (typeof beforeFunc === `function`) {
			beforeFunc()
		}

		clearTimeout(debounceDelayfunc)
		debounceDelayfunc = setTimeout(() => {
			if (typeof afterFunc === `function`) {
				afterFunc()
			}
		}, delay)
	}

	window.addEventListener(`resize`, debounce(resizeBeforefunc, resizeAfterfunc, 200))
}

/* GET INDEX */
const getIndex = (obj: HTMLElement) => {
	const parentObj = obj.parentElement !== null ? obj.parentElement.children : []

	return Array.from(parentObj).findIndex((item) => (item === obj ? item : false)) // .indexOf(obj)
}

/* GET PARAMETER */
const getParameter = (url: string, parameter: string) => {
	let array = []
	let resultValue = ``

	array = new Array(url.split(`?`)[0]).concat(url.split(`?`)[1].split(`&`))

	for (let i = 0; i < array.length; i += 1) {
		const param = array[i].split(`=`)

		if (param[0] === parameter) {
			if (param[1] !== undefined) {
				resultValue = param[1].toLowerCase()
				i = array.length
			}
		} else {
			resultValue = ``
		}
	}

	return resultValue
}

/* GET PARENTS
const getParents = (obj: HTMLElement, objTarget: string) => {
	const parents = []

	for (; obj && ((obj as unknown) !== document); obj = obj.parentElement) {
		if (objTarget !== undefined) {
			if (obj.matches(objTarget) === true) {
				parents.push(obj)
			}
		}
	}

	return parents
}
*/

/* GET SIBLINGS
const getSiblings = (obj: HTMLElement) => {
	const siblings = []

	do {
		if (obj.nodeType !== 3) {
			siblings.push(obj)
		}
	} while ((obj as unknown) = obj.nextSibling)

	return siblings
}
*/

/* PADVALUE */
const padValue = (value: number | string, text = `0`, length = 2) => {
	const chkValue = value.toString()

	return chkValue.padStart(length, text)
}

/* SPLIT YMD */
const splitYmd = (value: number | string, symbol = `.`) => {
	const data = {
		number: value.toString(),
		point: 0,
		str: ``
	}

	const {
		number
	} = data

	let {
		point, str
	} = data

	point = number.length % 4
	str = number.substring(0, point)

	while (point < number.length) {
		if (str !== ``) {
			str += symbol
		}

		let dotpoint = 4

		if (str !== ``) {
			dotpoint = 2
		}

		str += number.substring(point, point + dotpoint)
		point += dotpoint
	}

	return str
}

type animationFrameDataProps = Record<string, {
	animationFrame: number;
	animationFrameEffectX: number;
	animationFrameEffectY: number;
}>

const animationFrameData = {} as animationFrameDataProps

/* CANCEL ANIMATION */
const cancelAnimation = (name: string) => {
	if (animationFrameData[name] !== undefined) {
		window.cancelAnimationFrame(animationFrameData[name].animationFrame)
	}
}

/* SCROLL XY TO */
const scrollXYTo = (
	name: string,
	obj: HTMLElement,
	fromX: number,
	fromY: number,
	toX: number,
	toY: number,
	duration: number,
	callbackFunc?: () => void,
	type = `linear`
) => {
	if (animationFrameData[name] === undefined) {
		animationFrameData[name] = {
			animationFrame: 0,
			animationFrameEffectX: 0,
			animationFrameEffectY: 0
		}
	}

	const data = {
		changeX: toX - fromX,
		changeY: toY - fromY,
		currentTime: 0,
		increment: 20,
		startX: fromX,
		startY: fromY
	}

	const {
		increment, startX, changeX, startY, changeY
	} = data

	let {
		currentTime
	} = data

	// t = current time
	// b = start value
	// c = change in value
	// d = duration
	const easeAnimation = (t: number, b: number, c: number, d: number) => {
		let calcT = t

		if (type === `easeOutQuad`) {
			calcT /= d

			const easeCalc1 = -c * calcT
			const easeCalc2 = easeCalc1 * (calcT - 2)
			const easeCalcValue = easeCalc2 + b

			return easeCalcValue
		}

		const easeCalc1 = c * calcT
		const easeCalc2 = easeCalc1 / d
		const easeCalcValue = easeCalc2 + b

		return easeCalcValue
	}

	const animateScroll = () => {
		currentTime += increment

		animationFrameData[name].animationFrameEffectX = easeAnimation(currentTime, startX, changeX, duration)
		animationFrameData[name].animationFrameEffectY = easeAnimation(currentTime, startY, changeY, duration)

		obj.scrollTo(animationFrameData[name].animationFrameEffectX, animationFrameData[name].animationFrameEffectY)

		if (currentTime < duration) {
			window.cancelAnimationFrame(animationFrameData[name].animationFrame)

			animationFrameData[name].animationFrame = window.requestAnimationFrame(animateScroll)
		} else if (callbackFunc !== undefined) {
			callbackFunc()
		}
	}

	if (duration >= 100) {
		animateScroll()
	} else {
		obj.scrollTo(toX, toY)
	}
}

/* SORT */
type sortDataProps = Record<string, number | string>

const sortData = (data: sortDataProps[], key: string, type: `asc` | `desc` = `asc`) => {
	let result = null

	if (type === `asc`) {
		result = data.sort((data1: sortDataProps, data2: sortDataProps) => {
			if (data1[key] > data2[key]) {
				return 1
			}

			if (data1[key] < data2[key]) {
				return -1
			}

			return 0
		})
	} else {
		result = data.sort((data1: sortDataProps, data2: sortDataProps) => {
			if (data1[key] < data2[key]) {
				return 1
			}

			if (data1[key] > data2[key]) {
				return -1
			}

			return 0
		})
	}

	return result
}

/* TRANSLATE XY TO */
const translateXYTo = (
	name: string,
	obj: HTMLElement,
	fromX: number,
	fromY: number,
	toX: number,
	toY: number,
	duration: number,
	callbackFunc?: () => void,
	type = `linear`
) => {
	if (animationFrameData[name] === undefined) {
		animationFrameData[name] = {
			animationFrame: 0,
			animationFrameEffectX: 0,
			animationFrameEffectY: 0
		}
	}

	const data = {
		changeX: toX - fromX,
		changeY: toY - fromY,
		currentTime: 0,
		increment: 20,
		startX: fromX,
		startY: fromY
	}

	const {
		increment, startX, changeX, startY, changeY
	} = data

	let {
		currentTime
	} = data

	// t = current time
	// b = start value
	// c = change in value
	// d = duration
	const easeAnimation = (t: number, b: number, c: number, d: number) => {
		let calcT = t

		if (type === `easeOutQuad`) {
			calcT /= d

			const easeCalc1 = -c * calcT
			const easeCalc2 = easeCalc1 * (calcT - 2)
			const easeCalcValue = easeCalc2 + b

			return easeCalcValue
		}

		const easeCalc1 = c * calcT
		const easeCalc2 = easeCalc1 / d
		const easeCalcValue = easeCalc2 + b

		return easeCalcValue
	}

	const newObj = obj

	const animateScroll = () => {
		currentTime += increment

		animationFrameData[name].animationFrameEffectX = easeAnimation(currentTime, startX, changeX, duration)
		animationFrameData[name].animationFrameEffectY = easeAnimation(currentTime, startY, changeY, duration)

		// eslint-disable-next-line @stylistic/max-len
		newObj.style.transform = `translate(${animationFrameData[name].animationFrameEffectX}px, ${animationFrameData[name].animationFrameEffectY}px)`

		if (currentTime < duration) {
			window.cancelAnimationFrame(animationFrameData[name].animationFrame)

			animationFrameData[name].animationFrame = window.requestAnimationFrame(animateScroll)
		} else if (callbackFunc !== undefined) {
			callbackFunc()
		}
	}

	if (duration >= 100) {
		animateScroll()
	} else {
		newObj.style.transform = `translate(${toX}px, ${toY}px)`
	}
}

/* SCROLL MENU */
const scrollMenu = (name: string, obj: HTMLElement, targetName = `.centered-menu`, scroll = true, duration = 300) => {
	const menu: HTMLDivElement | null = obj.closest(targetName)

	if (menu !== null) {
		const menuWrap = menu.parentElement

		const scrollChk1 = obj.offsetWidth - menu.offsetWidth
		const scrolllChk2 = scrollChk1 / 2

		let left = scroll === true ? obj.offsetLeft - menu.offsetLeft + scrolllChk2 : obj.scrollLeft

		if (left < 0) {
			left = 0
		} else if (left > menu.scrollWidth - menu.offsetWidth) {
			left = menu.scrollWidth - menu.offsetWidth
		}

		if (menuWrap !== null) {
			if (left === 0) {
				menuWrap.classList.add(`first`)
			} else {
				menuWrap.classList.remove(`first`)
			}

			if (left + menu.offsetWidth >= menu.scrollWidth) {
				menuWrap.classList.add(`end`)
			} else {
				menuWrap.classList.remove(`end`)
			}
		}

		if (scroll === true) {
			scrollXYTo(name, menu, menu.scrollLeft, 0, left, 0, duration)
		}
	}
}

const getPageTitle = (pathname: string, searchParams: URLSearchParams) => {
	const setSearchParams = Object.fromEntries(searchParams)

	const splitUrl = (pathname ?? ``).split(/\?/gim)[0].split(`/`)

	let pageName = splitUrl[2] || splitUrl[1]

	if (pageName === ``) {
		pageName = `Index`
	} else if (pageName === `login`) {
		pageName = titleData.member.login.main

		if (splitUrl[3] !== undefined) {
			const subPageName = splitUrl[3]

			if (subPageName !== undefined) {
				pageName = titleData.member.login[subPageName]
			}
		}
	} else if (pageName === `join`) {
		pageName = titleData.member.join.main

		if (splitUrl[3] !== undefined) {
			const subPageName = splitUrl[3]

			if (subPageName === `complete` || subPageName === `info`) {
				pageName = titleData.member.join[subPageName ?? ``]
			}
		}
	} else if (pageName === `find`) {
		pageName = titleData.member.find.id

		if (pathname.match(/\/find\/pw/gim) !== null) {
			pageName = titleData.member.find.pw
		}
	} else if (pageName === `myinfo`) {
		if (splitUrl[3] !== undefined) {
			const subPageName = splitUrl[3].split(`?`)[0]

			pageName = titleData.member.myinfo.main

			if (subPageName !== `auth` && subPageName !== `info`) {
				pageName = titleData.member.myinfo[subPageName]

				if (subPageName === `newaddress` && setSearchParams.no) {
					pageName = titleData.member.myinfo.newaddressmodify
				} else if (subPageName === `bank` && setSearchParams.no) {
					pageName = titleData.member.myinfo.newbankmodify
				}
			}
		}
	} else if (pageName === `resign`) {
		pageName = titleData.member.resign.main
	} else if (pageName === `studio` || pageName === `schedule` || pageName === `livedetail` || pageName === `player`) {
		pageName = titleData.live.studio.index
	} else if (pageName === `404`) {
		pageName = `Error`
	}

	return pageName
}

const getHeaderType = (url: string) => {
	const splitUrl = (url ?? ``).split(/\?/gim)[0].split(`/`)

	const deviceChk1 = splitUrl[2] === `login`
	const deviceChk2 = splitUrl[2] === `join` && splitUrl[3] !== `moreinfo`

	const deviceChk3 =
		splitUrl[2] === `myinfo` &&
		splitUrl[3] !== `info` &&
		splitUrl[3] !== `edit` &&
		splitUrl[3] !== `auth`

	const deviceChk4 = splitUrl[2] === `find` || splitUrl[2] === `resign`

	const deviceChk5StudioViewAll = splitUrl[2] === `studio` && splitUrl[3] === `viewall`
	const deviceChk5 = splitUrl[2] === `player` || deviceChk5StudioViewAll

	if (deviceChk1 === true || deviceChk2 === true || deviceChk3 === true || deviceChk5 === true) {
		const deviceChk1Chk = deviceChk1 === true && (splitUrl[3] === `memberanotherauth` || splitUrl[3] === `snspopup`)
		const deviceChk2SnsAuth = deviceChk2 === true && splitUrl[3] === `snsauth`
		const deviceChk3Chk = deviceChk3 === true && (splitUrl[3] === `findmap` || splitUrl[3] === `snsauth`)

		if (deviceChk1Chk === true || deviceChk2SnsAuth === true || deviceChk3Chk === true || deviceChk5 === true) {
			return `empty`
		}

		return splitUrl[1] === `pc` ? `logoOnly` : `headerOnly`
	}

	if (splitUrl[1] === `pc`) {
		if (deviceChk3 === false || deviceChk2 === false) {
			return `subHeaderOnly`
		}
	}

	if (deviceChk4 === true) {
		return `headerOnly${splitUrl[1] === `mobile` ? `AppBanner` : ``}`
	}

	if (splitUrl[1] === `` || splitUrl[1] === `healthcheck`) {
		return `empty`
	}

	return `sub${splitUrl[1] === `mobile` ? `AppBanner` : ``}`
}

const getFooterType = (url: string) => {
	const splitUrl = url.split(/\?/gim)[0].split(`/`)

	const deviceChk1 = splitUrl[2] === `login` || splitUrl[2] === `resign`
	const deviceChk1MobileChk = deviceChk1 === true && splitUrl[1] === `mobile`

	const deiviceChk1PcChk =
		deviceChk1 === true &&
		splitUrl[1] === `pc` &&
		(splitUrl[3] === `memberanotherauth` || splitUrl[3] === `sns` || splitUrl[3] === `snspopup`)

	const deviceChk2 = splitUrl[2] === `join`

	const deviceChk3 =
		splitUrl[2] === `myinfo` && splitUrl[3] !== `info` && splitUrl[3] !== `edit` && splitUrl[3] !== `auth`

	const deviceChk4 = splitUrl[2] === `find`

	const deviceChk5StudioViewAll = splitUrl[2] === `studio` && splitUrl[3] === `viewall`
	const deviceChk5 = splitUrl[2] === `livedetail` || splitUrl[2] === `player` || deviceChk5StudioViewAll

	if (splitUrl[1] === `mobile`) {
		const deviceChk1MemberConfirm = deviceChk1 === true && splitUrl[3] === `memberconfirm`

		if (deviceChk1MemberConfirm === true || deviceChk2 === true || deviceChk4 === true) {
			return `navOnly`
		}
	}

	if (
		splitUrl[1] === `` ||
		splitUrl[1] === `healthcheck` ||
		deviceChk1MobileChk === true ||
		deiviceChk1PcChk === true ||
		deviceChk3 === true ||
		deviceChk5 === true
	) {
		return `empty`
	}

	return `sub`
}

const encryptDataFunc = async (data: string) => {
	const encoder = new TextEncoder()
	const dataBuffer = encoder.encode(data)

	if (data) {
		const getPemKey = (await keysPublicApi()).result.public_key.split(`----- `)[1].split(` -----`)[0]

		const binaryDer = Uint8Array.from(atob(getPemKey), (c) => c.charCodeAt(0))

		const key = await crypto.subtle.importKey(
			`spki`,
			binaryDer.buffer,
			{
				hash: `SHA-256`,
				name: `RSA-OAEP`
			},
			true,
			[
				`encrypt`
			]
		)

		const encryptedBuffer = await crypto.subtle.encrypt(
			{
				iv: crypto.getRandomValues(new Uint8Array(12)),
				name: `RSA-OAEP`
			},
			key,
			dataBuffer
		)

		const uint8ArrayToBase64 = async (uint8Array: ArrayLike<number>) => new Promise((resolve) => {
			let binaryString = ``

			for (let i = 0; i < uint8Array.length; i += 1) {
				binaryString += String.fromCharCode(uint8Array[i])
			}

			const base64String = btoa(binaryString)

			resolve(base64String)
		})

		const unit8Value = new Uint8Array(encryptedBuffer)

		const result = await uint8ArrayToBase64(unit8Value)

		return result
	}

	return false
}

const decryptDataFunc = async (data: string) => {
	const encryptedData = data

	if (encryptedData) {
		let getPemKey = process.env.decryptReleaseKey as string

		if (process.env.NODE_ENV !== `production`) {
			getPemKey = process.env.decryptStgKey as string
		}

		const binaryDer = Uint8Array.from(atob(getPemKey), (c) => c.charCodeAt(0))

		const base64ToUint8Array = (base64: string) => {
			const binaryString = atob(base64)
			const binaryStringLength = binaryString.length
			const bytes = new Uint8Array(binaryStringLength)

			for (let i = 0; i < binaryStringLength; i += 1) {
				bytes[i] = binaryString.charCodeAt(i)
			}

			return bytes
		}

		const key = await crypto.subtle.importKey(
			`pkcs8`,
			binaryDer.buffer,
			{
				hash: `SHA-256`,
				name: `RSA-OAEP`
			},
			true,
			[
				`decrypt`
			]
		)

		const decryptedBuffer = await crypto.subtle.decrypt(
			{
				name: `RSA-OAEP`
			},
			key,
			base64ToUint8Array(encryptedData)
		)

		const decoder = new TextDecoder()
		const decryptedData = decoder.decode(decryptedBuffer)

		const result = decryptedData

		return result
	}

	return false
}

const classNameFunc = (classNames: string) => {
	const convertClassName = classNames.trim().replace(/\s+/gim, ` `)

	return convertClassName
}

const stringTrimFunc = (classNames: string) => {
	const convertString = classNames.trim().replace(/\n\s+/gim, ` `)

	return convertString
}

export {
	device,
	addComma,
	addCookie,
	addHyphen,
	apiFunc,
	appVersionChkFunc,
	chkText,
	dgScroll,
	dgResize,
	getIndex,
	getParameter,

	// getParents,
	// getSiblings,
	padValue,
	splitYmd,
	cancelAnimation,
	scrollXYTo,
	translateXYTo,
	scrollMenu,
	sortData,
	getPageTitle,
	getHeaderType,
	getFooterType,
	encryptDataFunc,
	decryptDataFunc,
	classNameFunc,
	stringTrimFunc
}
